import { RequestType } from '../../../common/RequestTypes';
import { RestNetworkObject } from './../../../common/RestNetworkObject';
import {
  UpdateClosingPeriodRequest,
  UpdateClosingPeriodRequestPayload,
} from './UpdateClosingPeriodRequest';

export class UpdateClosingPeriodNetworkObject extends RestNetworkObject<UpdateClosingPeriodRequestPayload> {
  constructor(params: UpdateClosingPeriodRequest) {
    super(
      params,
      `/closing-periods/${params.closingPeriod.id}`,
      RequestType.PUT
    );
  }

  override getParams(): UpdateClosingPeriodRequestPayload {
    const { name, from, to, accommodationId, roomTypeId } =
      this.params.closingPeriod;
    return { closingPeriod: { name, from, to, accommodationId, roomTypeId } };
  }
}
