import { RequestType } from '../../../../common/RequestTypes';
import { RestNetworkObject } from './../../../../common/RestNetworkObject';
import { GetAccommodationClosingPeriodsRequest } from './GetAccommodationClosingPeriodsRequest';

export class GetAccommodationClosingPeriodsNetworkObject extends RestNetworkObject<undefined> {
  constructor(params: GetAccommodationClosingPeriodsRequest) {
    super(
      undefined,
      `/accommodations/${params.accommodationId}/closing-periods`,
      RequestType.GET
    );
  }
}
