import { defineStore } from 'pinia'
import { IdModal } from '../constants/IdModal'
import { CardTypes } from '../constants/cardTypes'
import { LoadingIds } from '../constants/loadingIds'
import { ClosingPeriod } from '../submodules/sharedTypes/common/ClosingPeriod'
import { CreateClosingPeriodNetworkObject } from '../submodules/sharedTypes/communication/closing-periods/CreateClosingPeriodNetworkObject'
import { CreateClosingPeriodResponse } from '../submodules/sharedTypes/communication/closing-periods/CreateClosingPeriodResponse'
import { GetAccommodationClosingPeriodsNetworkObject } from './../submodules/sharedTypes/communication/accommodations/id/closing-periods/GetAccommodationClosingPeriodsNetworkObject'
import { GetAccommodationClosingPeriodsResponse } from './../submodules/sharedTypes/communication/accommodations/id/closing-periods/GetAccommodationClosingPeriodsResponse'
import { DeleteClosingPeriodNetworkObject } from './../submodules/sharedTypes/communication/closing-periods/id/DeleteClosingPeriodNetworkObject'
import { UpdateClosingPeriodNetworkObject } from './../submodules/sharedTypes/communication/closing-periods/id/UpdateClosingPeriodNetworkObject'
import { UpdateClosingPeriodResponse } from './../submodules/sharedTypes/communication/closing-periods/id/UpdateClosingPeriodResponse'
import { utilNetwork } from './../utils/UtilNetwork'
import { useAccommodationsStore } from './accommodations'
import { useLoadingStore } from './loading'
import { useModalStore } from './modals'
import { useNotificationsStore } from './notifications'

type ClosingDatesStore = {
	closingDates: ClosingPeriod[]
	selectedClosingDate: number
}

export const useClosingDatesStore = defineStore('closing dates store', {
	state: (): ClosingDatesStore => ({
		closingDates: [],
		selectedClosingDate: 0,
	}),
	actions: {
		addClosingDate(closingPeriod: ClosingPeriod): void {
			const request = new CreateClosingPeriodNetworkObject({
				closingPeriod,
			})

			utilNetwork.simpleRequest(request, (res: CreateClosingPeriodResponse) => {
				this.closingDates.push(res.closingPeriod)

				const roomTypeId = res.closingPeriod.roomTypeId
				const accommodationStore = useAccommodationsStore()
				const message =
					roomTypeId == undefined
						? TranslationKeys.ADDED_CLOSING_PERIOD_TEXT
						: TranslationKeys.ADDED_ROOM_CLOSING_PERIOD_TEXT
				const messageReplacements =
					roomTypeId == undefined ? [res.closingPeriod.name] : [accommodationStore.getRoomTypeNameOrDefault(roomTypeId)]

				const notificationsStore = useNotificationsStore()
				notificationsStore.addNotification({
					canClose: false,
					title: TranslationKeys.ADDED_CLOSING_PERIOD,
					message,
					messageReplacements,
					cardType: CardTypes.SUCCESS,
				})
			})
		},
		setClosingDates(closingDates: GetAccommodationClosingPeriodsResponse) {
			this.closingDates = closingDates.closingPeriods
		},
		removeClosingDate(id: number): void {
			const index = this.closingDates.findIndex((element) => element.id === id)
			if (index >= 0) {
				this.selectedClosingDate = index
				useModalStore().addModal(IdModal.DeleteClosingPeriod)
			}
		},
		removeClosingDateConfirmed(): void {
			const closingDate = this.getSelectedClosingPeriod!
			const request = new DeleteClosingPeriodNetworkObject({
				id: closingDate.id!,
			})
			utilNetwork.simpleRequest(request, () => {
				const roomTypeId = closingDate.roomTypeId
				const accommodationStore = useAccommodationsStore()
				const message =
					roomTypeId == undefined
						? TranslationKeys.REMOVED_CLOSING_PERIOD_TEXT
						: TranslationKeys.REMOVED_ROOM_CLOSING_PERIOD_TEXT
				const messageReplacements =
					roomTypeId == undefined ? [closingDate.name] : [accommodationStore.getRoomTypeNameOrDefault(roomTypeId)]

				const notificationsStore = useNotificationsStore()
				notificationsStore.addNotification({
					canClose: false,
					title: TranslationKeys.REMOVED_CLOSING_PERIOD,
					message,
					messageReplacements,
					cardType: CardTypes.SUCCESS,
				})

				const index = this.closingDates.findIndex((el) => el.id === closingDate.id)
				this.closingDates.splice(index, 1)
			})
		},
		replaceClosingDate(closingPeriod: ClosingPeriod) {
			const id = closingPeriod.id
			const index = this.closingDates.findIndex((element) => element.id === id)

			this.closingDates[index] = closingPeriod
			const request = new UpdateClosingPeriodNetworkObject({
				closingPeriod,
			})
			utilNetwork.simpleRequest(request, (closingPeriod: UpdateClosingPeriodResponse) => {
				const accommodations = useAccommodationsStore()
				const message =
					closingPeriod.closingPeriod.roomTypeId == undefined
						? TranslationKeys.UPDATED_CLOSING_PERIOD_TEXT
						: TranslationKeys.UPDATED_ROOM_CLOSING_PERIOD_TEXT
				const messageReplacements =
					closingPeriod.closingPeriod.roomTypeId == undefined
						? [closingPeriod.closingPeriod.name]
						: [accommodations.getRoomTypeNameOrDefault(closingPeriod.closingPeriod.roomTypeId)]

				const notificationStore = useNotificationsStore()
				notificationStore.addNotification({
					canClose: false,
					title: TranslationKeys.UPDATED_CLOSING_PERIOD,
					message,
					messageReplacements,
					cardType: CardTypes.SUCCESS,
				})
			})
		},
		async requestClosingDates(accommodationId: number) {
			await useLoadingStore().getPromiseDependency(LoadingIds.ACCOMMODATIONS)

			const request = new GetAccommodationClosingPeriodsNetworkObject({
				accommodationId,
			})
			utilNetwork.simpleRequest(request, this.setClosingDates)
		},
		async requestCurrentClosingDates() {
			const accommodationStore = useAccommodationsStore()
			return await this.requestClosingDates(accommodationStore.getCurrentAccommodation.id)
		},
	},
	getters: {
		getStructureClosingDates(): ClosingPeriod[] {
			return this.closingDates.filter((el: ClosingPeriod) => el.roomTypeId == undefined)
		},
		getRoomsClosingDates(): ClosingPeriod[] {
			return this.closingDates.filter((el: ClosingPeriod) => el.roomTypeId != undefined)
		},
		getSelectedClosingPeriod(): ClosingPeriod | undefined {
			return this.closingDates[this.selectedClosingDate]
		},
		getSelectedClosingPeriodName(): string {
			const closingDate = this.getSelectedClosingPeriod
			if (closingDate == undefined) {
				return ''
			}
			const accommodations = useAccommodationsStore()

			return closingDate.roomTypeId == undefined
				? closingDate.name
				: accommodations.getRoomTypeNameOrDefault(closingDate.roomTypeId)
		},
		getClosingDatesByAccommodationAndRoomTypeId() {
			return (accommodationId: number, roomTypeId: number) => []
		},
	},
})
