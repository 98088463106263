import { RequestType } from '../../common/RequestTypes';
import { RestNetworkObject } from './../../common/RestNetworkObject';
import { CreateClosingPeriodRequestPayload } from './CreateClosingPeriodRequest';

export class CreateClosingPeriodNetworkObject extends RestNetworkObject<CreateClosingPeriodRequestPayload> {
  constructor(params: CreateClosingPeriodRequestPayload) {
    super(params, '/closing-periods', RequestType.POST);
  }

  override getParams(): CreateClosingPeriodRequestPayload {
    const { name, from, to, accommodationId, roomTypeId } =
      this.params.closingPeriod;
    return { closingPeriod: { name, from, to, accommodationId, roomTypeId } };
  }
}
