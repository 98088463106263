import { RequestType } from '../../../common/RequestTypes';
import { RestNetworkObject } from './../../../common/RestNetworkObject';
import { DeleteClosingPeriodRequest } from './DeleteClosingPeriodRequest';

export class DeleteClosingPeriodNetworkObject extends RestNetworkObject<
  DeleteClosingPeriodRequest,
  {}
> {
  constructor(params: DeleteClosingPeriodRequest) {
    super(params, `/closing-periods/${params.id}`, RequestType.DELETE);
  }

  override getParams(): {} {
    return {};
  }
}
